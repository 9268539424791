import { PencilSimpleIcon, PushpinIcon, TrashIcon } from '@c/icons';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import Chip from '@ui/Chip';
import { useAuth } from 'context/AuthContext';
import { AddressDocument } from '@models/address';
import AddAddressModal from '@c/modals/AddAddressModal';
import { useState } from 'react';
import { removeUserAddress, updateUserByKey } from '@util/firestore/users';

interface AddressCardProps {
  address: AddressDocument;
  displayOnly?: boolean;
  noBg?: boolean;
}
const AddressCard = ({ address, displayOnly, noBg }: AddressCardProps) => {
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();

  const deleteAddress = async () => {
    if (!userDoc) return;
    if (address.is_default) return alert('Cannot delete default address');
    if (address.is_shop_location) return alert('Cannot delete shop location');
    if (window.confirm('Are you sure you want to delete this address?')) {
      await removeUserAddress(userDoc.uid, address);
      queryClient.invalidateQueries({
        queryKey: ['authUser'],
      });
    }
  };

  const setDefault = async () => {
    if (!userDoc) return;
    const addresses =
      userDoc.addresses?.map((a) => ({ ...a, is_default: false })) ?? [];
    const i = addresses.findIndex(
      (a) => a.address_line1 === address.address_line1
    );
    addresses[i] = { ...address, is_default: true };
    await updateUserByKey(userDoc.uid, 'addresses', addresses);
    await queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`flex w-full max-w-[43rem] flex-col items-start gap-[2.4rem] rounded-3xl border-[1px] ${
        noBg ? '' : 'bg-brand-white'
      }  p-[2.4rem]`}
    >
      <AddAddressModal
        isOpen={isOpen}
        dismiss={() => setIsOpen(false)}
        address={address}
      />
      <div className="flex w-full items-center justify-between gap-[1.6rem]">
        <h3 className="text-[1.8rem] font-semibold leading-[2.7rem] text-brand-light-black">
          {address.name}
        </h3>
        {!displayOnly && (
          <div className="flex items-start gap-[1.2rem] font-semibold text-brand-secondary">
            <div className="hidden sm:block">
              <Button
                type="text"
                text="Edit"
                leadingIcon={<PencilSimpleIcon />}
                onClick={() => setIsOpen(true)}
                height="extraSmall"
                width="extraSmall"
              />
            </div>
            <div className="sm:hidden">
              <Button
                type="text"
                leadingIcon={<PencilSimpleIcon />}
                onClick={() => setIsOpen(true)}
                height="extraSmall"
                width="extraSmall"
              />
            </div>

            {userDoc?.addresses && userDoc?.addresses.length > 1 && (
              <>
                <span className="h-[2rem] w-[1px] border-[1px]"></span>
                <Button
                  type="text"
                  leadingIcon={<TrashIcon />}
                  height="extraSmall"
                  width="extraSmall"
                  onClick={deleteAddress}
                />
              </>
            )}
          </div>
        )}
      </div>
      <div className="flex w-full flex-col items-start gap-[0.8rem]">
        <span className="w-full text-[1.5rem] leading-[2.2rem] text-brand-lighter-black">
          {address.address_line1},
          {address.address_line2 ? address.address_line2 + ',' : ''}
          {address.city_locality}, {address.state_province},{' '}
          {address.postal_code},{address.country_code}
        </span>
        <span className="w-full text-[1.5rem] leading-[2.2rem] text-brand-lighter-black">
          {address.phone}
        </span>
      </div>
      {!displayOnly && (
        <div className="flex w-full items-center font-semibold">
          {address.is_default ? (
            <Chip text="Main Address" color="gray" />
          ) : (
            <Button
              type="text"
              text="Set as Main Address"
              height="extraSmall"
              width="extraSmall"
              onClick={() => setDefault()}
              leadingIcon={<PushpinIcon />}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AddressCard;
