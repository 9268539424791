import { CurationsIcon } from '@c/icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import Spinner from '@ui/Spinner';
import { functions } from '@util/firebase';
import { httpsCallable } from 'firebase/functions';
import { ChatDocument } from 'models/chat';

export default function SummarizeChat({ chat }: { chat: ChatDocument }) {
  const summarizeChatMutation = useMutation({
    mutationFn: async () => {
      return httpsCallable<ChatDocument, string>(
        functions,
        'v2_summarizeChat'
      )(chat);
    },
    onError: (error) => {
      alert((error as Error).message);
    },
  });
  return (
    <Popover className={'fixed bottom-12 right-12 z-10 hidden sm:block'}>
      <PopoverButton
        type="button"
        className="rounded-full border-black p-4 text-brand-secondary shadow-xl hover:animate-pulse"
        onClick={() => summarizeChatMutation.mutate()}
      >
        {summarizeChatMutation.isPending ? <Spinner /> : <CurationsIcon />}
      </PopoverButton>
      {}
      <PopoverPanel
        className={
          'absolute bottom-12 right-12 z-10 flex h-[50rem] w-[40rem] overflow-y-scroll bg-white p-4 shadow-xl '
        }
      >
        {summarizeChatMutation.data?.data || (
          <div className="m-auto h-[5rem] w-[5rem] self-center">
            <Spinner />
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
}
