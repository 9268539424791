import { formatRange } from '@util/index';
import { useAuth } from 'context/AuthContext';
import dayjs from 'dayjs';
import { Router } from 'lucide-react';
import { ProductDocument } from 'models/product';
import { UserDocument } from 'models/user';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

export default function ProductConditions({
  product,
  deliveryDates,
  toggleFitmentWarning,
  textBig,
  source = 'product-details',
}: {
  product: ProductDocument;
  deliveryDates?: {
    earliest: string;
    latest: string;
  };
  toggleFitmentWarning?: () => void;
  textBig?: boolean;
  source?: 'product-details' | 'quick-view';
}) {
  const { userDoc } = useAuth();
  const router = useRouter();

  let dates = deliveryDates;

  if (product.is_flat_rate) {
    dates = {
      earliest: dayjs().add(5, 'days').toISOString(),
      latest: dayjs().add(10, 'days').toISOString(),
    };
  }

  const getDeliveryText = () => {
    return formatRange(dates);
  };

  const textSize = textBig ? 'text-[2.5rem]' : 'text-[1.6rem]';

  return (
    <div className="flex flex-col gap-y-4">
      <Item title="Condition" value={product.condition} textSize={textSize} />

      <DeliveryItem
        deliveryDates={deliveryDates}
        userDoc={userDoc}
        getDeliveryText={getDeliveryText}
        textSize={textSize}
      />

      {product.category.includes('Parts') && (
        <div className="flex items-center gap-x-2">
          <span className={`${textSize} font-semibold text-brand-black`}>
            Compatibility:
          </span>

          <button
            type="button"
            className={`${textSize} font-medium text-brand-black underline`}
            onClick={() => {
              if (source === 'product-details') {
                toggleFitmentWarning?.();

                const fitmentSection = document.getElementById('fitment');

                if (fitmentSection) {
                  setTimeout(() => {
                    fitmentSection.scrollIntoView({ behavior: 'smooth' });
                  }, 0);
                }
              } else {
                router.push(`/product/${product.slug}#fitment`);
              }
            }}
          >
            See fitment details
          </button>
        </div>
      )}

      {['Riding Gear', 'Casual', 'Apparel'].includes(product.category ?? '') &&
        !product?.has_variations && (
          <Item
            title="Size"
            value={
              product.category2 === 'Gear Combos'
                ? `${product.size?.letter} / ${product.size?.number}`
                : product.size?.letter
                ? product.size?.letter
                : product.size?.number
                ? product.size?.number.toString()
                : ''
            }
            textSize={textSize}
          />
        )}

      {product.sku && (
        <Item title="SKU" value={product?.sku} textSize={textSize} />
      )}
    </div>
  );
}

const DeliveryItem = ({
  deliveryDates,
  userDoc,
  getDeliveryText,
  textSize,
}: {
  deliveryDates:
    | {
        earliest: string;
        latest: string;
      }
    | undefined;
  userDoc: UserDocument | null | undefined;
  getDeliveryText: () => string | null;
  textSize: string;
}) => {
  const showLoading = !deliveryDates && userDoc;

  const today = dayjs();
  const threeDaysFromNow = today.add(3, 'days');

  return (
    <div className="flex items-center gap-x-2">
      <span className={`${textSize} font-semibold text-brand-black`}>
        Delivery:
      </span>

      <span
        className={`${textSize} flex items-center gap-x-2 text-brand-black`}
      >
        Get it by{' '}
        {showLoading ? (
          <div className="h-5 w-32 animate-pulse rounded-full bg-brand-light-gray" />
        ) : (
          <>
            {!userDoc ? (
              <span className="font-semibold">
                {today.format('MMM D')} - {threeDaysFromNow.format('MMM D')}
              </span>
            ) : (
              <span className="font-semibold">{getDeliveryText()}</span>
            )}
          </>
        )}{' '}
        if you order today!
      </span>
    </div>
  );
};

const Item = ({
  title,
  value,
  linkText,
  linkHref,
  textSize,
}: {
  title: string;
  value?: string;
  linkText?: string;
  linkHref?: string;
  textSize: string;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className={`${textSize} font-semibold text-brand-black`}>
        {title}:
      </span>

      {linkHref ? (
        <Link
          href={linkHref}
          className={`${textSize} text-brand-black underline`}
        >
          {linkText}
        </Link>
      ) : (
        <span className={`${textSize} text-brand-black`}>{value}</span>
      )}
    </div>
  );
};
