import { useQuery } from '@tanstack/react-query';
import SafeImage from '@ui/SafeImage';
import { getProductById } from '@util/firestore/products';
import { useOffers } from './useOffers';
import { formatCurrency } from '@util/index';

export default function MobileMessageOffer({ offer_id }: { offer_id: string }) {
  const { offer } = useOffers(offer_id);
  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer!.product_id),
    enabled: !!offer?.product_id,
  });
  if (!product) return null;
  return (
    <div className="flex h-fit w-full gap-[1.5rem] p-[1.5rem]">
      <div className="relative h-[5.5rem] w-[5.5rem]">
        <SafeImage src={product.thumbnail} fill alt="product image" />
      </div>

      <div className="flex flex-col">
        <span className="line-clamp-1 h-[2.875rem] text-[1.875rem] font-medium leading-[2.875rem]">
          {product.title}
        </span>
        <span className="text-[1.625rem] font-semibold text-[#7F7F7F]">
          {formatCurrency(product.price)}
        </span>
      </div>
    </div>
  );
}
