'use client';

import { CreditCard } from '@c/checkout/Payment';
import FormLabel from '@c/forms/controls/FormLabel';
import FormSelect from '@c/forms/controls/FormSelect';
import BaseModal from '@c/modals/BaseModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import CurrencyInput from '@ui/Input/masked/CurrencyInput';
import { getPaymentMethods } from '@util/firestore/payments';
import { formatCurrency } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { capitalize, update } from 'lodash';
import { XIcon } from 'lucide-react';
import { AddressDocument } from 'models/address';
import { OfferDocument } from 'models/offer';
import { Montserrat } from 'next/font/google';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import ItemShippingSelect from '@c/checkout/ItemShippingSelect';
import { Rate } from '@util/firestore/shipengine';
import { ProductDocument } from 'models/product';
import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import Button from '@ui/Button';
import { getProductById } from '@util/firestore/products';
import { acceptOffer, updateOffer } from '@util/firestore/offers';
import { logError } from '@util/logError';
import { useOffers } from './useOffers'; // Adjust the import path as necessary
import { useStripeContext } from 'context/StripeContext';
import { l } from 'nuqs/dist/serializer-5da93b5e';
import { getTax } from '@util/firestore/taxjar';
import { getShippingRegion } from '@util/firestore/cart';
import { ChatDocument } from 'models/chat';
import { useToastContext } from 'context/ToastContext';
import useDebounce from '@util/hooks/useDebounce';

const montserrat = Montserrat({ subsets: ['latin'] });

interface OfferOperationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  type: 'counter' | 'accept' | 'decline';
  offer: OfferDocument;
  counterPrice?: number;
  setCounterPrice?: (price: number) => void;
  tax?: number;
  setTax?: (tax: number) => void;
  selectedPaymentMethod?: any;
  setSelectedPaymentMethod?: (payment: any) => void;
  selectedShippingOption?: any;
  setSelectedShippingOption?: (shipping: any) => void;
}

interface OfferOperationBodyProps {
  offer: OfferDocument;
  onClose: () => void;
}

// Define the Zod schema for the form
const acceptOfferSchema = z.object({
  shipping_address: z.string().optional(),
  payment_method: z.string().optional(),
});

type AcceptOfferFormData = z.infer<typeof acceptOfferSchema>;

export default function OfferOperationModal({
  isOpen,
  onClose,
  type,
  offer,
}: OfferOperationModalProps) {
  const { userDoc } = useAuth();

  return (
    <BaseModal isOpen={isOpen} modalRounded hideCloseIcon noPadding>
      <div
        className={`${montserrat.className} flex w-full items-center justify-between border-b border-zinc-200 p-6`}
      >
        <div className="flex-grow text-center">
          <h1 className="text-[2.4rem] font-bold capitalize text-zinc-900">
            {type} Offer
          </h1>
        </div>

        <button onClick={onClose} className="text-zinc-700">
          <XIcon />
        </button>
      </div>

      {type === 'accept' && offer.seller_id === userDoc?.uid && (
        <AcceptOfferSellerBody offer={offer} onClose={onClose} />
      )}
      {type === 'accept' && offer.seller_id !== userDoc?.uid && (
        <AcceptOfferBuyerBody offer={offer} onClose={onClose} />
      )}

      {type === 'decline' && (
        <DeclineOfferBody offer={offer} onClose={onClose} />
      )}

      {type === 'counter' && (
        <CounterOfferBody offer={offer} onClose={onClose} />
      )}
    </BaseModal>
  );
}

const AcceptOfferSellerBody = ({ offer, onClose }: OfferOperationBodyProps) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { getNetEarnings, acceptThatOffer } = useOffers(offer.id);
  const { showErrorToast } = useToastContext();
  const { data: product } = useQuery({
    queryKey: ['product', offer.product_id],
    queryFn: () => getProductById(offer.product_id),
    enabled: !!offer.product_id,
  });
  return (
    <div className={montserrat.className}>
      <div className="my-8 flex flex-col items-center justify-center gap-y-8">
        <h3 className="w-[40rem] text-center text-[2rem] text-zinc-800">
          By accepting this offer, you agree to sell this item for{' '}
          <span className="font-bold text-black">
            {formatCurrency(offer.total)}
          </span>
        </h3>

        <div className="mt-4 flex w-full justify-center">
          <div className="flex items-center justify-center rounded-2xl bg-brand-lightest-gray px-4 py-2 text-[1.8rem] text-zinc-900">
            ({formatCurrency(offer.price)} +{' '}
            {formatCurrency(offer.shipping_cost)} Shipping +{' '}
            {formatCurrency(offer.tax)} Tax)
          </div>
        </div>

        <div className="flex flex-col items-center gap-y-4">
          <h3 className="w-[40rem] text-center text-[2rem] text-zinc-800">
            Your earnings for this order will be
          </h3>

          {product && (
            <h3 className="text-[3rem] font-bold text-black">
              {formatCurrency(getNetEarnings(offer))}
            </h3>
          )}
        </div>
      </div>

      <div className="flex w-full flex-col items-center justify-center bg-brand-lightest-gray p-12">
        <span className="text-[1.8rem] text-zinc-700">
          Once accepted, offers are binding and cannot be cancelled.
        </span>

        <div className="mt-8 flex w-full items-center gap-x-8">
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-[1.5rem] border border-brand-black bg-inherit p-4 text-[1.8rem] font-semibold text-brand-black"
            onClick={onClose}
            disabled={loadingSubmit}
          >
            Cancel
          </button>

          <button
            type="button"
            className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#1E7A1E] p-4 text-[1.8rem] font-semibold text-white disabled:bg-brand-gray disabled:text-black"
            onClick={async () => {
              setLoadingSubmit(true);
              try {
                await acceptThatOffer();
              } catch (e) {
                showErrorToast((e as Error).message);
              }
            }}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? 'Accepting...' : 'Accept'}
          </button>
        </div>
      </div>
    </div>
  );
};

const AcceptOfferBuyerBody = ({ offer, onClose }: OfferOperationBodyProps) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedRate, setSelectedRate] = useState<Rate | null>(null);
  const [ratesError, setRatesError] = useState<Error | null>(null);
  const [cardListCollapsed, setCardListCollapsed] = useState(false);
  const [calculatingTax, setCalculatingTax] = useState(false);

  const queryClient = useQueryClient();
  const { acceptThatOffer } = useOffers(offer.id);
  const { data: paymentMethodData, isLoading } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
  });

  const { userDoc } = useAuth();

  const { data: product } = useQuery({
    queryKey: ['product', offer.product_id],
    queryFn: () => getProductById(offer.product_id),
    enabled: !!offer.product_id,
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<AcceptOfferFormData>({
    resolver: zodResolver(acceptOfferSchema),
    defaultValues: {
      shipping_address: offer.address
        ? `${offer.address.address_line1} - ${offer.address.city_locality}, ${offer.address.state_province}, ${offer.address.postal_code}`
        : '',
      payment_method: offer.payment?.id || '',
    },
  });

  const form = watch();

  useEffect(() => {
    if (
      paymentMethodData &&
      paymentMethodData.length > 0 &&
      !offer.payment?.id
    ) {
      const defaultPaymentMethod =
        paymentMethodData[paymentMethodData.length - 1];
      setValue('payment_method', defaultPaymentMethod.id ?? '');
    }
  }, [paymentMethodData]);

  useEffect(() => {
    if (selectedRate && selectedRate.rate_id !== offer.rate_id) {
      setCalculatingTax(true);
      getTax({
        product_id: offer.product_id,
        seller_id: offer.seller_id,
        to_address:
          offer.address ||
          userDoc?.addresses.find(
            (a) =>
              `${a.address_line1} - ${a.city_locality}, ${a.state_province}, ${a.postal_code}` ===
              form.shipping_address
          )!,
        total: offer.price + selectedRate.total_amount,
        shipping: selectedRate.total_amount,
      }).then((tax) => {
        updateOffer(offer.id, {
          tax: tax.amount,
          rate_id: selectedRate.rate_id,
          shipping_cost: selectedRate.total_amount,
          total: offer.price + tax.amount + selectedRate.total_amount,
        }).then(() => {
          queryClient.invalidateQueries({
            queryKey: ['offer', offer.id],
          });
          setCalculatingTax(false);
        });
      });
    } else if (
      product?.is_flat_rate &&
      `${offer.address.address_line1} - ${offer.address.city_locality}, ${offer.address.state_province}, ${offer.address.postal_code}` !==
        form.shipping_address
    ) {
      const address = userDoc?.addresses.find(
        (a) =>
          `${a.address_line1} - ${a.city_locality}, ${a.state_province}, ${a.postal_code}` ===
          form.shipping_address
      );
      if (address) {
        const region = getShippingRegion(address);
        const shipping_cost = product.shipping_costs?.find(
          (c) => c.code === region
        )?.cost;
        if (!shipping_cost) {
          setError('root', {
            type: 'manual',
            message: 'Shipping cost not found for this region',
          });
          return;
        }
        setCalculatingTax(true);
        getTax({
          product_id: offer.product_id,
          seller_id: offer.seller_id,
          to_address:
            offer.address ||
            userDoc?.addresses.find(
              (a) =>
                `${a.address_line1} - ${a.city_locality}, ${a.state_province}, ${a.postal_code}` ===
                form.shipping_address
            )!,
          total: offer.price + shipping_cost,
          shipping: shipping_cost,
        }).then((tax) => {
          updateOffer(offer.id, {
            tax: tax.amount,
            shipping_cost,
            total: offer.price + tax.amount + shipping_cost,
          }).then(() => {
            queryClient.invalidateQueries({
              queryKey: ['offer', offer.id],
            });
            setCalculatingTax(false);
          });
        });
      }
    }
  }, [selectedRate]);

  const addressOptions =
    userDoc?.addresses?.map((address) => ({
      id: `${address.address_line1} - ${address.city_locality}, ${address.state_province}, ${address.postal_code}`,
      label: `${address.address_line1} - ${address.city_locality}, ${address.state_province}, ${address.postal_code}`,
      value: `${address.address_line1} - ${address.city_locality}, ${address.state_province}, ${address.postal_code}`,
    })) ?? [];

  const selectedPayment = paymentMethodData?.find(
    (method) =>
      method.id === form.payment_method || method.id === offer.payment?.id
  );
  const onFormSubmit = handleSubmit(async (data) => {
    if (loadingSubmit || calculatingTax) return;
    setLoadingSubmit(true);

    const selectedAddress = data.shipping_address
      ? userDoc?.addresses?.find(
          (a) =>
            `${a.address_line1} - ${a.city_locality}, ${a.state_province}, ${a.postal_code}` ===
            data.shipping_address
        )
      : offer.address;

    if (!selectedPayment) {
      setLoadingSubmit(false);
      setError('payment_method', {
        type: 'manual',
        message: 'Please select a payment method',
      });
      return;
    }

    if (!selectedAddress?.address_line1) {
      setLoadingSubmit(false);
      setError('shipping_address', {
        type: 'manual',
        message: 'Please select a shipping address',
      });
      return;
    }

    if (!product?.is_flat_rate && !(selectedRate || offer.rate_id)) {
      setLoadingSubmit(false);
      setError('root', {
        type: 'manual',
        message: 'Please select a shipping rate',
      });
      return;
    }

    const updatedOffer = {
      payment: selectedPayment,
      shipping_cost: offer.shipping_cost,
      address: selectedAddress,
      tax: offer.tax,
      total: offer.price + offer.shipping_cost + offer.tax,
    };
    try {
      await updateOffer(offer.id, updatedOffer);
      await acceptThatOffer();
      onClose();
    } catch (e) {
      logError(e);
      setLoadingSubmit(false);
    }
  });

  return (
    <div className={montserrat.className}>
      <form onSubmit={onFormSubmit}>
        <div className="my-8 flex flex-col items-center justify-center gap-y-8">
          <h2 className="text-center text-[2rem] font-semibold text-zinc-800">
            {product?.title}
          </h2>

          <h3 className="w-[40rem] text-center text-[2rem] text-zinc-800">
            By accepting this offer, you agree to buy this item for{' '}
            <span className="font-bold text-black">
              {formatCurrency(offer.total)}
            </span>
          </h3>

          <div className="mt-4 flex w-full justify-center">
            <div className="flex items-center justify-center rounded-2xl bg-brand-lightest-gray px-4 py-2 text-[1.8rem] text-zinc-900">
              ({formatCurrency(offer.price)} +{' '}
              {formatCurrency(offer.shipping_cost)} Shipping +{' '}
              {formatCurrency(offer.tax)} Tax)
            </div>
          </div>
        </div>

        {isLoading && !offer.payment?.id ? (
          <div className="relative h-[14rem] w-full p-8">
            <div className="h-[10rem] w-full animate-pulse rounded-2xl bg-brand-lightest-gray" />

            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-[1.8rem] font-medium text-zinc-600">
                Loading Payment Methods...
              </span>
            </div>
          </div>
        ) : (
          <>
            {offer.is_exclusive && (
              <div className="space-y-8 p-8">
                <FormLabel
                  value="Shipping Address"
                  errorMessage={errors.shipping_address?.message}
                >
                  <Controller
                    name="shipping_address"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        disabled={!addressOptions.length}
                        placeholder="Select Shipping Address"
                        options={addressOptions}
                        error={!!errors.shipping_address}
                        control={control}
                        allowEmpty={false}
                        onChange={(e) => {
                          field.onChange(e.value);
                          if (!product?.is_flat_rate) {
                            setSelectedRate(null);
                          }
                        }}
                      />
                    )}
                  />
                </FormLabel>

                <FormLabel
                  value="Payment Method"
                  required
                  errorMessage={errors.payment_method?.message}
                >
                  {selectedPayment && (
                    <div className="mb-4">
                      <CreditCard
                        key={`selected-${selectedPayment.id}`}
                        label={`${capitalize(selectedPayment.brand)} - ${
                          selectedPayment.last4
                        }`}
                        name={selectedPayment.name ?? ''}
                        selected={true}
                        showArrow
                        onClickArrow={() => {
                          setCardListCollapsed(!cardListCollapsed);
                        }}
                        arrowDirection={cardListCollapsed ? 'up' : 'down'}
                      />
                    </div>
                  )}

                  {cardListCollapsed && (
                    <Controller
                      name="payment_method"
                      control={control}
                      render={({ field }) => (
                        <div className="flex flex-col gap-y-4">
                          {paymentMethodData
                            ?.filter(
                              (option) => option.id !== selectedPayment?.id
                            )
                            .map((option) => (
                              <CreditCard
                                key={option.id}
                                label={`${capitalize(option.brand)} - ${
                                  option.last4
                                }`}
                                name={option.name ?? ''}
                                selected={false}
                                onClick={() => {
                                  field.onChange(option.id);
                                  if (cardListCollapsed) {
                                    setCardListCollapsed(false);
                                  }
                                }}
                              />
                            ))}
                        </div>
                      )}
                    />
                  )}
                </FormLabel>

                {form.shipping_address && product && !product.is_flat_rate && (
                  <OfferShippingOptions
                    product={product}
                    shipTo={userDoc?.addresses.find(
                      (a) =>
                        `${a.address_line1} - ${a.city_locality}, ${a.state_province}, ${a.postal_code}` ===
                        form.shipping_address
                    )}
                    offer_amount={offer.price}
                    selectedRate={selectedRate}
                    setSelectedRate={setSelectedRate}
                    ratesError={ratesError}
                    setRatesError={setRatesError}
                  />
                )}
              </div>
            )}
          </>
        )}

        <div className="flex w-full flex-col items-center justify-center bg-brand-lightest-gray p-12">
          <span className="text-[1.8rem] text-zinc-700">
            Once accepted, offers are binding and cannot be cancelled.
          </span>

          <div className="mt-8 flex w-full items-center gap-x-8">
            <button
              type="button"
              className="flex w-full items-center justify-center rounded-[1.5rem] border border-brand-black bg-inherit p-4 text-[1.8rem] font-semibold text-brand-black"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={loadingSubmit}
              className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#1E7A1E] p-4 text-[1.8rem] font-semibold text-white"
            >
              {loadingSubmit ? 'Accepting...' : 'Accept'}
            </button>
          </div>
          {errors?.root && (
            <p className="mt-[1.3rem] text-red-500">{errors.root.message}</p>
          )}
        </div>
      </form>
    </div>
  );
};

// Add this component to handle shipping options
const OfferShippingOptions = ({
  product,
  shipTo,
  offer_amount,
  selectedRate,
  setSelectedRate,
  ratesError,
  setRatesError,
}: {
  product: ProductDocument;
  shipTo?: AddressDocument;
  offer_amount: number;
  selectedRate: Rate | null;
  setSelectedRate: (rate: Rate | null) => void;
  ratesError: Error | null;
  setRatesError: (error: Error | null) => void;
}) => {
  const [showShippingOptions, setShowShippingOptions] = useState(false);

  return (
    <div className="flex flex-col gap-[1.6rem]">
      <span className="font-semibold">Selected Shipping</span>
      {selectedRate ? (
        <>
          <div className="flex w-full gap-[1.6rem]">
            {selectedRate.carrier_code === 'fedex' ? (
              <FedExIcon />
            ) : selectedRate.carrier_code === 'ups' ? (
              <UPS />
            ) : (
              <USPSIcon />
            )}
            <div className="flex grow items-center gap-5">
              <div>
                <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                  {selectedRate.delivery_days} Days
                </h5>
                <p className="text-[1.4rem] text-brand-gray">
                  {selectedRate.service_type}
                </p>
              </div>
              <div className="ml-auto text-[1.6rem] font-semibold">
                {formatCurrency(selectedRate.total_amount)}
              </div>
            </div>
          </div>
          <Button
            type="text"
            onClick={() => setShowShippingOptions((s) => !s)}
            text={
              showShippingOptions
                ? 'Hide Shipping Options'
                : 'Show More Shipping Options'
            }
          />
        </>
      ) : !ratesError ? (
        <div className="flex w-full flex-col gap-4">
          <span className="animate-pulse text-[1.4rem] font-semibold">
            Loading shipping options...
          </span>
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4">
          <span className="text-[1.4rem] font-semibold text-brand-red">
            {ratesError.message}
          </span>
        </div>
      )}
      {shipTo && (
        <div className="max-h-[35rem] overflow-y-auto">
          <ItemShippingSelect
            chooseCheapestByDefault
            hideProduct
            product={product}
            shipment={product.shipment}
            shipTo={shipTo}
            hide={!showShippingOptions}
            offer_amount={offer_amount}
            selectedRateId={selectedRate?.rate_id ?? null}
            onOptionSelected={({ rate }) => {
              setSelectedRate(rate);
            }}
            onError={(error) => {
              logError('Error fetching shipping rates', error.message);
              setRatesError(error);
            }}
          />
        </div>
      )}
    </div>
  );
};

const DeclineOfferBody = ({ offer, onClose }: OfferOperationBodyProps) => {
  const { declineThatOffer } = useOffers(offer.id);
  const { showErrorToast } = useToastContext();
  return (
    <div className={montserrat.className}>
      <div className="mt-8 flex w-full items-center justify-center p-8">
        <h3 className="w-[40rem] text-center text-[2rem] text-zinc-800">
          Are you sure want to decline this offer? You can&apos;t undo this
          action.
        </h3>
      </div>

      <div className="mt-10 flex w-full flex-col items-center justify-center bg-brand-lightest-gray p-12">
        <div className="flex w-full items-center gap-x-8">
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-[1.5rem] border border-brand-black bg-inherit p-4 text-[1.8rem] font-semibold text-brand-black"
            onClick={onClose}
          >
            Cancel
          </button>

          <button
            type="button"
            className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#C41719] p-4 text-[1.8rem] font-semibold text-white"
            onClick={async () => {
              try {
                await declineThatOffer();
                onClose();
              } catch (e) {
                showErrorToast((e as Error).message);
              }
            }}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

const CounterOfferBody = ({ offer, onClose }: OfferOperationBodyProps) => {
  const [counterPrice, setCounterPrice] = useState<number>(0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const debouncedCounterPrice = useDebounce(counterPrice, 500);
  const [tax, setTax] = useState<number>(offer.tax);
  const [loadingTax, setLoadingTax] = useState(false);

  const { data: product } = useQuery({
    queryKey: ['product', offer.product_id],
    queryFn: () => getProductById(offer.product_id),
    enabled: !!offer.product_id,
  });

  useEffect(() => {
    if (debouncedCounterPrice && debouncedCounterPrice !== offer.price) {
      setLoadingTax(true);
      getTax({
        product_id: offer.product_id,
        seller_id: offer.seller_id,
        to_address: offer.address!,
        total: debouncedCounterPrice + offer.shipping_cost,
        shipping: offer.shipping_cost,
      })
        .then((tax) => {
          setTax(tax.amount);
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          setLoadingTax(false);
        });
    }
  }, [debouncedCounterPrice]);

  const { counterOffer } = useOffers();

  return (
    <div className={montserrat.className}>
      <div className="flex flex-col justify-start gap-y-8 px-12 py-8">
        <h3 className="text-[2rem] font-semibold text-zinc-900">
          Enter your counter offer price!
        </h3>

        <div className="max-w-[45rem] border-l-2 border-zinc-300 pl-8">
          {!counterPrice ? (
            <span className="text-[1.8rem] font-semibold text-zinc-500">
              Provide your counter offer price
            </span>
          ) : (
            <span className="text-[1.8rem] font-semibold text-zinc-500">
              If the buyer accepts, they will be charged this counter price (
              {formatCurrency(counterPrice)}) + {formatCurrency(tax ?? 0)} Tax +{' '}
              {formatCurrency(offer.shipping_cost)} Shipping.
            </span>
          )}
        </div>

        <div className="flex w-full flex-col gap-y-2">
          <div className="flex w-full items-center gap-x-2">
            <h3 className="text-[2rem] font-bold text-black">Counter price</h3>

            <h3 className="text-[2rem] font-bold text-brand-secondary">*</h3>
          </div>

          <div className="flex w-full items-center gap-x-4 rounded-[1.5rem] bg-brand-lightest-gray px-12 py-6">
            <span className="text-[1.8rem] font-bold text-black">
              <DollarIcon />
            </span>

            <CurrencyInput
              maskOptions={{
                decimalLimit: 2,
                integerLimit: 7,
                decimalSymbol: '.',
                allowDecimal: true,
              }}
              defaultValue={counterPrice === 0 ? '' : counterPrice?.toString()}
              placeholder="0.00"
              disabled={loadingSubmit}
              className="!text-[1.8rem]"
              onChange={(e) => {
                const newEvent = {
                  ...e,
                  target: {
                    ...e.target,
                    value:
                      e.target.value !== ''
                        ? parseFloat(e.target.value.replace(/[^0-9.]/g, ''))
                        : 0,
                  },
                };
                setCounterPrice(newEvent.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-8 flex w-full justify-center">
          <button
            type="button"
            disabled={loadingSubmit || loadingTax}
            className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#C41719] p-6 text-[2rem] font-semibold text-white disabled:bg-brand-gray disabled:text-black"
            onClick={async () => {
              if (loadingSubmit || loadingTax) return;
              setLoadingSubmit(true);
              if (product) {
                try {
                  await counterOffer({
                    offer: {
                      ...offer,
                      price: counterPrice,
                      tax,
                    },
                    product,
                  });
                  onClose();
                } catch (e) {
                  logError(e);
                }
              }
              setLoadingSubmit(false);
            }}
          >
            {loadingSubmit ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

const DollarIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 1.875V18.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 6.875C14.375 6.46462 14.2942 6.05826 14.1371 5.67911C13.9801 5.29997 13.7499 4.95547 13.4597 4.66529C13.1695 4.37511 12.825 4.14492 12.4459 3.98788C12.0667 3.83083 11.6604 3.75 11.25 3.75H8.4375C7.6087 3.75 6.81384 4.07924 6.22779 4.66529C5.64174 5.25134 5.3125 6.0462 5.3125 6.875C5.3125 7.7038 5.64174 8.49866 6.22779 9.08471C6.81384 9.67076 7.6087 10 8.4375 10H11.875C12.7038 10 13.4987 10.3292 14.0847 10.9153C14.6708 11.5013 15 12.2962 15 13.125C15 13.9538 14.6708 14.7487 14.0847 15.3347C13.4987 15.9208 12.7038 16.25 11.875 16.25H8.125C7.2962 16.25 6.50134 15.9208 5.91529 15.3347C5.32924 14.7487 5 13.9538 5 13.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
