import ItemShippingSelect from '@c/checkout/ItemShippingSelect';
import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import FormSelect from '@c/forms/controls/FormSelect';
import { ChevronLeftIcon, FedExIcon, PlusIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import { BProcess, ProcessStep } from '@c/process/BaseProcess';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddressDocument, addressSchema } from '@models/address';
import { BidDocument } from '@models/bid';
import { useStripe } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import SafeImage from '@ui/SafeImage';
import Spinner from '@ui/Spinner';
import { getItem, logEvent } from '@util/analytics';
import { getNumber } from '@util/createListingHelpers';
import { createBid } from '@util/firestore/bid/bid.service';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import {
  createOffer,
  createOfferIntent,
  getOfferId,
  getOfferMessage,
} from '@util/firestore/offers';
import { getPaymentMethods } from '@util/firestore/payments/payments.service';
import { getProductById } from '@util/firestore/products';
import { Rate } from '@util/firestore/shipengine';
import { getTax } from '@util/firestore/taxjar';
import { getPublicUserDoc } from '@util/firestore/users';
import { capitalize, formatCurrency, getHostUrl, isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { OfferDocument } from 'models/offer';
import { ProductDocument, Variation } from 'models/product';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import AddAddressModal from './AddAddressModal';
import AddPaymentMethodModal from './AddPaymentMethodModal';
import BaseModal from './BaseModal';
import AddressCard from '@c/cards/AddressCard';

const OfferShippingOptions = ({
  product,
  shipTo,
  setShippingCost,
  offer_amount,
  selectedRate,
  setSelectedRate,
  ratesError,
  setRatesError,
}: {
  product: ProductDocument;
  shipTo: AddressDocument;
  setShippingCost: (cost: number) => void;
  offer_amount: number;
  selectedRate: Rate | null;
  setSelectedRate: (rate: Rate | null) => void;
  ratesError: Error | null;
  setRatesError: (error: Error | null) => void;
}) => {
  const [showShippingOptions, setShowShippingOptions] = useState(false);

  useEffect(() => {
    setShippingCost(selectedRate?.total_amount || 0);
  }, [selectedRate?.total_amount, setShippingCost]);

  return (
    <div className="flex flex-col gap-[1.6rem]">
      <span className="font-semibold">Selected Shipping</span>
      {selectedRate ? (
        <>
          <div className="flex w-full gap-[1.6rem]">
            {selectedRate.carrier_code === 'fedex' ? (
              <FedExIcon />
            ) : selectedRate.carrier_code === 'ups' ? (
              <UPS />
            ) : (
              <USPSIcon />
            )}
            <div className="flex grow items-center gap-5">
              <div>
                <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                  {selectedRate.delivery_days} Days
                </h5>
                <p className="text-[1.4rem] text-brand-gray">
                  {selectedRate.service_type}
                </p>
              </div>
              <div className="ml-auto text-[1.6rem] font-semibold">
                {formatCurrency(selectedRate.total_amount)}
              </div>
            </div>
          </div>
          <Button
            type="text"
            onClick={() => setShowShippingOptions((s) => !s)}
            text={
              showShippingOptions
                ? 'Hide Shipping Options'
                : 'Show More Shipping Options'
            }
          />
        </>
      ) : !ratesError ? (
        <div className="flex w-full flex-col gap-4">
          {/* loading text */}
          <span className="animate-pulse text-[1.4rem] font-semibold">
            Loading shipping options...
          </span>
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
          <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4">
          <span className="text-[1.4rem] font-semibold text-brand-red">
            {ratesError.message}
          </span>
        </div>
      )}
      <div className="max-h-[35rem] overflow-y-auto">
        <ItemShippingSelect
          chooseCheapestByDefault
          hideProduct
          product={product}
          shipment={product.shipment}
          shipTo={shipTo}
          hide={!showShippingOptions}
          offer_amount={offer_amount}
          selectedRateId={selectedRate?.rate_id ?? null}
          onOptionSelected={({ rate }) => {
            setSelectedRate(rate);
          }}
          onError={(error) => {
            setRatesError(error);
          }}
        />
      </div>
    </div>
  );
};

interface MakeAnOfferModalProps {
  isOpen: boolean;
  dismiss: (openDrawer?: boolean) => void;
  product: ProductDocument;
  variant?: 'bid' | 'offer';
  highestBid?: BidDocument;
  isEditingBid?: boolean;
  currentUserBid?: BidDocument;
  bidInput?: string;
  selectedVariations?: Variation[] | null;
}

const OfferFormSchema = z.object({
  price: z.number().gt(0),
  maxPrice: z.number().optional(),
  message: z.string().optional(),
  shippingAddress: z.string().min(1),
  paymentMethodId: z.string().min(1),
  termsAndConditions: z.boolean().refine((v) => v === true, {
    message: 'You must agree to the terms and conditions',
  }),
});

type OfferSchemaType = z.infer<typeof OfferFormSchema>;

const MakeAnOfferModal = ({
  isOpen,
  dismiss,
  product,
  variant = 'offer',
  highestBid,
  isEditingBid,
  currentUserBid,
  bidInput,
  selectedVariations,
}: MakeAnOfferModalProps) => {
  const { userDoc } = useAuth();
  const defaultMaxBid =
    (currentUserBid?.max_bid ?? 0) > (highestBid?.bid_price ?? 0)
      ? currentUserBid?.max_bid?.toString()
      : '';

  function getPricePercentageOff(percentage: number) {
    const price = getNumber(product.price);

    return Number((price * (1 - percentage / 100)).toFixed(2));
  }

  function getDefaultValue(variation: string, highestBid: number) {
    if (variation === 'offer') {
      return getPricePercentageOff(15);
    }

    return highestBid + 1;
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<OfferSchemaType>({
    resolver: zodResolver(OfferFormSchema),
    defaultValues: {
      price: bidInput
        ? Number(bidInput)
        : getDefaultValue(variant, highestBid?.bid_price ?? product.price),
      maxPrice: getNumber(defaultMaxBid),
    },
  });

  const [openModal, setOpenModal] = useState<'address' | 'payment' | ''>('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [selectedRate, setSelectedRate] = useState<Rate | null>(null);
  const [ratesError, setRatesError] = useState<Error | null>(null);

  const [shippingCost, setShippingCost] = useState(
    !product.is_flat_rate ? selectedRate?.total_amount ?? 0 : 0
  );

  const { data: paymentMethodData, isLoading } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
    enabled: !!userDoc,
  });

  const addressOptions =
    userDoc?.addresses?.map((address) => ({
      id: `${address.address_line1}`,
      label: `${address.address_line1}`,
      value: JSON.stringify(address),
    })) ?? [];

  const paymentOptions = paymentMethodData?.map((paymentMethod) => ({
    id: paymentMethod.id ?? '',
    label: `${capitalize(paymentMethod.brand)} - ${paymentMethod.last4}`,
    value: paymentMethod.id ?? '',
  }));

  const form = watch();

  const [tax, setTax] = useState(0);
  const calcTax = useCallback(() => {
    const total = getNumber(form.price) + shippingCost;
    if (!form.shippingAddress || !product || !total) return;
    const shippingAddressObj = JSON.parse(form.shippingAddress);
    shippingAddressObj.address_line2 = shippingAddressObj.address_line2 ?? '';
    const shippingAddress = addressSchema.parse(shippingAddressObj);

    getTax({
      product_id: product.id,
      seller_id: product.seller_id,
      to_address: shippingAddress!,
      total,
      shipping: shippingCost,
    }).then((tax) => {
      setTax(tax.amount);
    });
  }, [form.price, form.shippingAddress, product, shippingCost]);

  useEffect(() => {
    if (userDoc?.addresses?.length && product.is_flat_rate) {
      if (!form.shippingAddress) {
        const addy =
          userDoc.addresses.find((a) => a.is_default) ?? userDoc.addresses[0];
        setValue('shippingAddress', JSON.stringify(addy));
        const shipping = calculateShipping(product, getShippingRegion(addy));
        setShippingCost(shipping?.cost ?? 0);
      } else {
        const shippingAddress = JSON.parse(
          form.shippingAddress
        ) as AddressDocument;
        const shipping = calculateShipping(
          product,
          getShippingRegion(shippingAddress)
        );
        setShippingCost(shipping?.cost ?? 0);
      }
    }
    calcTax();

    if (paymentMethodData?.length) {
      const mostRecent = paymentMethodData[paymentMethodData.length - 1];
      setValue('paymentMethodId', mostRecent.id!);
    }
  }, [
    userDoc,
    paymentMethodData,
    product,
    setValue,
    form.shippingAddress,
    calcTax,
  ]);

  useEffect(() => {
    setSelectedRate(null);

    if (!form.shippingAddress) {
      if (addressOptions.length) {
        setValue('shippingAddress', addressOptions[0].value);
        const shipping = calculateShipping(
          product,
          getShippingRegion(JSON.parse(addressOptions[0].value))
        );
        setShippingCost(shipping?.cost ?? 0);
      }
    }

    if (form.shippingAddress) {
      const shippingAddress = JSON.parse(
        form.shippingAddress
      ) as AddressDocument;
      const shipping = calculateShipping(
        product,
        getShippingRegion(shippingAddress)
      );
      setShippingCost(shipping?.cost ?? 0);
    }
  }, [form.shippingAddress]);

  const stripe = useStripe();

  const updateShipping = async () => {
    setLoadingRates(true);
    if (!form.shippingAddress) {
      setError(
        'shippingAddress',
        {
          type: 'manual',
          message: `No shipping address selected`,
        },
        { shouldFocus: true }
      );
      setLoadingRates(false);
      return;
    }
    const shippingAddress = JSON.parse(form.shippingAddress) as AddressDocument;

    const shipping = calculateShipping(
      product,
      getShippingRegion(shippingAddress)
    );

    if (product.is_flat_rate && !shipping) {
      setError(
        'shippingAddress',
        {
          type: 'manual',
          message: `No shipping options available for this product to this address`,
        },
        { shouldFocus: true }
      );
      setLoadingRates(false);
      return;
    }

    if (shipping) {
      setShippingCost(shipping.cost);
    }

    logEvent('add_shipping_info', {
      currency: 'USD',
      items: [getItem(product)],
      shipping_tier: selectedRate?.service_type ?? 'flat_rate',
    });

    setLoadingRates(false);
    setCurrentStep(2);
  };

  const updateBid = () => {
    const bidPrice = getNumber(form.price);
    let maxPrice: number | undefined;
    if (form.maxPrice) {
      maxPrice = getNumber(form.maxPrice);
    }
    if (highestBid && getNumber(form.price) <= highestBid?.bid_price) {
      setError('price', {
        type: 'manual',
        message: `Your bid must be at least $1 higher than the current bid. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }
    if (variant === 'bid' && maxPrice && maxPrice < form.price + 1) {
      setError('maxPrice', {
        type: 'manual',
        message: `Your max bid must be at least $1 higher than your current bid. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }
    const offer_id = currentUserBid?.offer_id;

    let username = userDoc?.username || 'Anonymous';
    username =
      username.charAt(0) + '***' + username.charAt(username.length - 1);

    const newBid = {
      product_id: product.id,
      username: username,
      bid_price: getNumber(form.price),
      ...(maxPrice &&
        maxPrice > bidPrice && {
          max_bid: maxPrice,
        }),
      uid: userDoc?.uid,
      offer_id,
      created: Date.now(),
      auto: false,
    } as BidDocument;
    // if date now is greater than end time, then bid is expired
    if (product.end_time && Date.now() > product.end_time) {
      setError('price', {
        type: 'manual',
        message: `This auction has ended`,
      });
      dismiss();
    }
    createBid(newBid).then(() => {
      dismiss();
    });
  };

  const onSubmit = async (data: OfferSchemaType) => {
    if (!userDoc || !stripe) return;
    const currProduct = await getProductById(product.id);
    if (!currProduct) return;

    if (currProduct.out_of_stock) {
      setError('price', {
        type: 'manual',
        message: `This product is out of stock`,
      });
      setCurrentStep(0);
      return;
    }
    setSubmitError('');

    if (!form.termsAndConditions) {
      setError(
        'termsAndConditions',
        {
          type: 'manual',
          message: `You must agree to the terms and conditions`,
        },
        { shouldFocus: true }
      );
    }

    const priceNumber = getNumber(data.price);
    if (
      variant === 'offer' &&
      priceNumber < (currProduct.minimum_offer_price ?? 0)
    ) {
      setError('price', {
        type: 'manual',
        message: `Your offer is lower than the buyers minimum offer price. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }
    if (variant === 'bid' && priceNumber < (highestBid?.bid_price ?? 0)) {
      setError('price', {
        type: 'manual',
        message: `Your bid is lower than current bid. Please try again.`,
      });
      setCurrentStep(0);
    }
    if (variant === 'offer' && priceNumber > currProduct.price) {
      setError('price', {
        type: 'manual',
        message: `Your offer is higher than the buyers asking price. Did you mean to buy now?`,
      });
      setCurrentStep(0);
      return;
    }
    setLoadingSubmit(true);
    const shippingAddressObj = JSON.parse(data.shippingAddress);
    shippingAddressObj.address_line2 = shippingAddressObj.address_line2 ?? '';
    const shippingAddress = addressSchema.parse(shippingAddressObj);
    if (currProduct.is_flat_rate) {
      const shipping = calculateShipping(
        currProduct,
        getShippingRegion(shippingAddress)
      );
      if (!shipping) {
        logEvent(
          'shipping_unavailable',
          {
            items: [getItem(currProduct)],
            currency: 'USD',
          },
          userDoc?.uid
        );
        throw new Error('Shipping Unavailable');
      }
      setShippingCost(shipping.cost);
    }
    const paymentMethod = paymentMethodData?.find(
      (paymentMethod) => paymentMethod.id === data.paymentMethodId
    );
    if (!paymentMethod?.id) throw new Error('Please select a payment method');
    const seller = await getPublicUserDoc({
      uid: currProduct.seller_id,
      noCache: true,
    });
    if (!seller) throw new Error('Seller not found');

    const now = Date.now();

    const letterVariation = selectedVariations?.find((v) => !v.is_number);
    const numberVariation = selectedVariations?.find((v) => v.is_number);

    const offer: OfferDocument = {
      id: getOfferId(),
      is_counter: false,
      is_exclusive: false,
      attribution: {},
      buyer_id: userDoc.uid,
      seller_id: seller.uid,
      rate_id: selectedRate?.rate_id ?? '',
      product_id: currProduct.id,
      current_price: currProduct.price,
      account_id: '', // added in backend
      customer_id: userDoc.customer_id,
      is_auction: variant === 'bid',
      ...(!!currProduct.end_time && {
        end_time: currProduct.end_time,
      }),
      ...(!!data.maxPrice && { max_bid: getNumber(data.maxPrice) }),
      price: getNumber(data.price),
      shipping_cost: shippingCost,
      total: shippingCost + getNumber(data.price) + tax,
      message: data.message ?? '',
      address: shippingAddress,
      payment: paymentMethod,
      uids: [userDoc.uid, seller.uid],
      created: now,
      time_remaining:
        variant === 'offer' ? now + 172_800_000 : currProduct.end_time!, //add 48 hours
      from_web: true,
      state: 0,
      tax,
      ...(selectedVariations && {
        variation: {
          ...(letterVariation && {
            letter: letterVariation.size as string,
          }),
          ...(numberVariation && {
            number: +numberVariation.size as number,
          }),
        },
      }),
    };

    if (variant === 'offer') {
      offer.message = getOfferMessage(
        currProduct.title,
        offer.price!,
        offer.shipping_cost!,
        offer.tax!,
        offer.message,
        selectedVariations,
        currProduct.size,
        offer.address
      );
    }

    if (!offer.price) {
      setError('price', {
        type: 'manual',
        message: `Please enter a valid price`,
      });
      setCurrentStep(0);
      return;
    }

    if (!currProduct.is_flat_rate && !offer.rate_id) {
      setError('shippingAddress', {
        type: 'manual',
        message: `Please select a shipping rate`,
      });
      setCurrentStep(1);
      return;
    }

    if (!currProduct.is_flat_rate && !offer.shipping_cost) {
      setError('shippingAddress', {
        type: 'manual',
        message: `Please select a shipping rate`,
      });
      setCurrentStep(1);
      return;
    }

    const offerIntent = await createOfferIntent(offer.price, offer.customer_id);
    const return_url = `${getHostUrl()}/dashboard/offers?retry=true`;
    const { error } = await stripe.confirmCardSetup(offerIntent.data, {
      payment_method: paymentMethod.id,
      return_url,
    });

    if (error) {
      setSubmitError(
        error.message ??
          'Error submitting offer. Please try again or contact support'
      );
      setLoadingSubmit(false);
      return;
    } else {
      await createOffer(offer);
      logEvent(
        'created_offer',
        {
          items: [getItem(currProduct)],
          offer_id: offer.id,
        },
        userDoc.uid
      );
      if (variant === 'bid') {
        let username = userDoc.username || 'Anonymous';
        username =
          username.charAt(0) + '***' + username.charAt(username.length - 1);
        const bid: BidDocument = {
          uid: userDoc.uid,
          username: username,
          offer_id: offer.id,
          product_id: currProduct.id,
          bid_price: offer.price,
          ...(!!form.maxPrice && { max_bid: form.maxPrice }),
          created: now,
          auto: false,
        };
        // if date now is greater than end time, then bid is expired
        if (currProduct.end_time && Date.now() > currProduct.end_time) {
          setError('price', {
            type: 'manual',
            message: `This auction has ended`,
          });
          dismiss();
          return;
        }
        await createBid(bid);
      }
      dismiss(true);
      setLoadingSubmit(false);
    }
  };

  const [currentStep, setCurrentStep] = useState(0);

  const reviewShipping = () => {
    calcTax();
    // if price and terms and conditions are valid, go to review step
    if (form.price) {
      const priceNumber = getNumber(form.price);
      const maxBid = getNumber(form.maxPrice ?? '0');
      if (
        variant === 'offer' &&
        priceNumber < (product.minimum_offer_price ?? 0)
      ) {
        setError('price', {
          type: 'manual',
          message: `Your offer is lower than the buyers minimum offer price. Please try again.`,
        });
        return;
      }

      if (variant === 'bid' && priceNumber < product.price + 1) {
        setError('price', {
          type: 'manual',
          message: `Your bid must be at least $1 higher than the current bid. Please try again.`,
        });
        setCurrentStep(0);
        return;
      }
      if (variant === 'bid' && maxBid && maxBid < priceNumber + 1) {
        setError('maxPrice', {
          type: 'manual',
          message: `Your max bid must be at least $1 higher than your current bid. Please try again.`,
        });
        setCurrentStep(0);
        return;
      }
      if (variant === 'offer' && priceNumber > product.price) {
        setError('price', {
          type: 'manual',
          message: `Your offer is higher than the buyers asking price. Did you mean to buy now?`,
        });
        return;
      }
      clearErrors('price');
      setCurrentStep(1);
    } else {
      // else, show errors
      if (!form.price) {
        setError('price', {
          type: 'manual',
          message: `Please enter ${variant} price`,
        });
      }
    }
  };

  function handleOfferClick(price: number) {
    setValue('price', Number(price.toFixed(2)));
  }

  function OfferDetails() {
    return (
      <div className="rounded-xl border border-brand-primary-lighter bg-brand-off-white p-6 text-black">
        <div className="flex w-full justify-between">
          <h1 className="text-[2rem] font-semibold">Your Offer</h1>
          <h1 className="text-[2rem] font-semibold">
            {formatCurrency(getNumber(form.price))}
          </h1>
        </div>

        <div className="my-4">
          <div className="flex w-full justify-between">
            <span className="text-[1.8rem] text-brand-gray">Total tax:</span>

            <span className="text-[1.8rem] text-brand-gray">
              {formatCurrency(tax)}
            </span>
          </div>
        </div>

        {product.is_flat_rate ||
          (shippingCost > 0 && (
            <div className="my-4">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] text-brand-gray">
                  Shipping costs:
                </span>

                <span className="text-[1.8rem] text-brand-gray">
                  {formatCurrency(shippingCost)}
                </span>
              </div>
            </div>
          ))}

        {!product.is_flat_rate && shippingCost === 0 && (
          <span className="text-[1.5rem] text-brand-gray">
            Shipping costs will be calculated in the next step.
          </span>
        )}
      </div>
    );
  }

  const steps: ProcessStep<any>[] = [
    {
      id: 'agreement',
      content: (
        <div className="flex max-w-[44rem] flex-col gap-[2.4rem] px-1">
          {variant === 'bid' ? (
            <>
              <span className="w-full text-brand-dark-gray">
                If you have the highest bid once the auction expires, you will
                be automatically charged your bid price.{' '}
                <Link
                  className="text-brand-secondary"
                  href="/faq"
                  target="_blank"
                >
                  Learn more
                </Link>
              </span>
              <div className="rounded-lg bg-[#E9F4E9] p-6">
                <div className="pb-1 pt-2 text-xl font-light text-neutral-800">
                  Current Highest Bid
                </div>
                <div className="text-h3 font-medium text-green-700">
                  {formatCurrency(highestBid?.bid_price ?? product.price)}
                </div>
              </div>
            </>
          ) : (
            <>
              <span className="w-full text-brand-dark-gray">
                This offer is binding, you will automatically be charged if the
                seller accepts.
              </span>
              <div className="flex items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl"
                  alt={'product image'}
                  src={product.thumbnail}
                />
                <div className="flex flex-col gap-[0.2rem] text-brand-lightest-black">
                  <span className="line-clamp-2">{product.title}</span>
                  <span className="text-[1.8rem] font-bold text-brand-dark-gray">
                    Price: {formatCurrency(product.price)}
                  </span>
                  {selectedVariations?.map((variation) => (
                    <span
                      key={variation.size}
                      className="text-[1.4rem] text-brand-gray"
                    >
                      Size: {variation.size}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col items-start gap-[1.6rem]">
            <Controller
              control={control}
              name={`price`}
              render={({ field: { value, onChange, onBlur } }) => (
                <FormLabel
                  required
                  labelClasses="font-medium"
                  value={variant === 'offer' ? 'Your offer' : 'Your bid'}
                  flex
                >
                  <FormCurrencyInputFancy
                    placeholder={'0.00'}
                    error={!!errors.price}
                    onChange={(e) => {
                      clearErrors('price');
                      onChange(e);
                    }}
                    value={value > 0 ? value.toFixed(2) : ''}
                    defaultValue={form.price.toString()}
                    onBlur={onBlur}
                  />
                </FormLabel>
              )}
            />

            <div className="grid w-full grid-cols-3 gap-x-4">
              {/* {[20, 15, 10].map((percentage) => (
                <>
                  {variant === 'offer' ? (
                    <button
                      className={`flex cursor-pointer flex-col justify-center rounded-xl p-4 ${
                        form.price === getPricePercentageOff(percentage)
                          ? 'border border-brand-secondary bg-brand-secondary/30 text-brand-secondary'
                          : 'border border-brand-primary-lighter bg-transparent hover:bg-brand-off-white'
                      }`}
                      key={percentage}
                      type="button"
                      onClick={() =>
                        handleOfferClick(getPricePercentageOff(percentage))
                      }
                    >
                      <h1 className={'text-[1.5rem] font-semibold'}>
                        {formatCurrency(getPricePercentageOff(percentage))}
                      </h1>
                      <span>{percentage}% off</span>
                    </button>
                  ) : (
                    <button
                      className={`flex cursor-pointer flex-col justify-center rounded-xl p-4 ${
                        form.price === getBidPercentage(percentage)
                          ? 'border border-brand-secondary bg-brand-secondary/30 text-brand-secondary'
                          : 'border border-brand-primary-lighter bg-transparent hover:bg-brand-off-white'
                      }`}
                      key={percentage}
                      type="button"
                      onClick={() =>
                        handleOfferClick(getBidPercentage(percentage))
                      }
                    >
                      <h1 className={'text-[1.5rem] font-semibold'}>
                        {formatCurrency(getBidPercentage(percentage))}
                      </h1>
                      <span>+{percentage}%</span>
                    </button>
                  )}
                </>
              ))} */}

              {variant === 'offer' && (
                <>
                  {[20, 15, 10].map((percentage) => (
                    <button
                      className={`flex cursor-pointer flex-col justify-center rounded-xl p-4 ${
                        form.price === getPricePercentageOff(percentage)
                          ? 'border border-brand-secondary bg-brand-secondary/30 text-brand-secondary'
                          : 'border border-brand-primary-lighter bg-transparent hover:bg-brand-off-white'
                      }`}
                      key={percentage}
                      type="button"
                      onClick={() =>
                        handleOfferClick(getPricePercentageOff(percentage))
                      }
                    >
                      <h1 className={'text-[1.5rem] font-semibold'}>
                        {formatCurrency(getPricePercentageOff(percentage))}
                      </h1>
                      <span>{percentage}% off</span>
                    </button>
                  ))}
                </>
              )}
            </div>

            {errors.price && (
              <span className="text-[1.2rem] text-brand-red">
                {errors.price.message}
              </span>
            )}
            {/* {variant === 'offer' && (
              <FormLabel value="Message to seller (Optional)">
                <FormInput
                  placeholder="Give message to seller"
                  error={!!errors.message}
                  {...register('message')}
                />
              </FormLabel>
            )} */}
            {variant === 'bid' && (
              <>
                <Controller
                  control={control}
                  name={`maxPrice`}
                  render={({ field: { onChange, onBlur } }) => (
                    <FormLabel
                      labelClasses="font-medium"
                      value="Max Bid (Optional)"
                    >
                      <FormCurrencyInputFancy
                        full
                        placeholder="0.00"
                        error={!!errors.maxPrice}
                        onChange={(e) => {
                          clearErrors('maxPrice');
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        defaultValue={form.maxPrice?.toString() ?? ''}
                      />
                    </FormLabel>
                  )}
                />
                {errors.maxPrice && (
                  <span className="text-[1.2rem] text-brand-red">
                    {errors.maxPrice.message}
                  </span>
                )}
                <span className="-mt-4 mb-3 text-[1rem] text-brand-gray">
                  We will automatically place your bids until the price reaches
                  your max bid.
                </span>
              </>
            )}
          </div>

          <OfferDetails />

          <div className="flex w-full items-center justify-end gap-[1.6rem] pb-[2.4rem]">
            <div className="font-semibold text-brand-secondary">
              <Button
                type="text"
                text="Cancel"
                width="small"
                onClick={() => dismiss()}
              />
            </div>
            <Button
              type="secondary"
              text={variant === 'bid' ? 'Place bid' : 'Continue'}
              buttonType="button"
              width="normal"
              onClick={() => {
                if (isEditingBid) {
                  updateBid();
                } else {
                  reviewShipping();
                }
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 'shipping-and-payment',
      title: 'Shipping & Payment',
      content: (
        <div className="flex min-h-[42rem] max-w-[44rem] flex-col justify-between gap-[2.4rem] px-[2.4rem]">
          <div className="flex flex-col gap-[1.6rem]">
            <div>
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <SafeImage
                    className="rounded-2xl"
                    src={product.thumbnail}
                    width={44}
                    height={44}
                    alt={''}
                  />
                  <div className="flex flex-col">
                    <h4 className="text-h4 line-clamp-2 leading-8">
                      {product.title}
                    </h4>
                    <span className="text-lg text-brand-gray">
                      {product.category}
                    </span>
                    {selectedVariations?.map((variation) => (
                      <span
                        key={variation.size}
                        className="text-[1.4rem] text-brand-gray"
                      >
                        Size: {variation.size}
                      </span>
                    ))}
                  </div>
                </div>
                <span className="font-semibold">
                  {formatCurrency(
                    variant === 'offer' ? product.price : getNumber(form.price)
                  )}
                </span>
              </div>
            </div>
            <div className="flex w-full flex-row items-end justify-between gap-[1.6rem] sm:flex-row sm:gap-0">
              <div className="w-3/4">
                <FormLabel
                  value="Shipping address"
                  required
                  errorMessage={errors.shippingAddress?.message}
                >
                  <FormSelect
                    disabled={!addressOptions.length}
                    allowEmpty={false}
                    placeholder="Select shipping address"
                    error={!!errors.shippingAddress}
                    options={addressOptions}
                    rules={{ required: true }}
                    control={control}
                    name="shippingAddress"
                    onChange={(e) => {
                      setSelectedRate(null);
                      clearErrors('shippingAddress');
                      setValue('shippingAddress', e.value);
                      const selectedAddress = JSON.parse(e.value);
                      const shipping = calculateShipping(
                        product,
                        getShippingRegion(selectedAddress)
                      );
                      setShippingCost(shipping?.cost ?? 0);
                    }}
                  />
                </FormLabel>
              </div>
              <div className="flex w-1/4 justify-end sm:ml-4 sm:w-fit">
                <Button
                  width={'small'}
                  height={'small'}
                  buttonType="button"
                  type="tertiary"
                  leadingIcon={<PlusIcon />}
                  onClick={() => setOpenModal('address')}
                />
                <AddAddressModal
                  isOpen={openModal === 'address'}
                  dismiss={(address) => {
                    setOpenModal('');
                    if (address && product.is_flat_rate) {
                      setValue('shippingAddress', JSON.stringify(address));
                      const shipping = calculateShipping(
                        product,
                        getShippingRegion(address)
                      );
                      setShippingCost(shipping?.cost ?? 0);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex w-full flex-row items-end justify-between gap-[1.6rem] sm:gap-0">
              <div className="w-3/4 ">
                {!isLoading ? (
                  <FormLabel value="Payment method" required>
                    <FormSelect
                      disabled={!paymentOptions?.length}
                      allowEmpty={false}
                      placeholder="Select payment method"
                      error={!!errors.paymentMethodId}
                      options={paymentOptions ?? []}
                      control={control}
                      name="paymentMethodId"
                    />
                  </FormLabel>
                ) : (
                  //  animate pulse in the shape of a rounded select input
                  <div className="flex h-[4.8rem] w-full animate-pulse items-center justify-center rounded-full bg-gray-200">
                    Loading payment methods...
                  </div>
                )}
              </div>
              <div className="flex w-1/4 justify-end sm:ml-4 sm:w-fit">
                <Button
                  width={'small'}
                  height={'small'}
                  buttonType="button"
                  type="tertiary"
                  leadingIcon={<PlusIcon />}
                  onClick={() => setOpenModal('payment')}
                />
                <AddPaymentMethodModal
                  isOpen={openModal === 'payment'}
                  dismiss={(paymentMethodId) => {
                    setOpenModal('');
                    if (paymentMethodId) {
                      setValue('paymentMethodId', paymentMethodId);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {form.shippingAddress && !product.is_flat_rate && (
            <OfferShippingOptions
              product={product}
              shipTo={addressSchema.parse(JSON.parse(form.shippingAddress))}
              setShippingCost={setShippingCost}
              offer_amount={getNumber(form.price)}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
              ratesError={ratesError}
              setRatesError={setRatesError}
            />
          )}

          <OfferDetails />

          <div className="flex justify-end">
            <div className="mr-4 font-semibold text-brand-secondary">
              <Button
                buttonType="button"
                type="text"
                text="Cancel"
                width="small"
                onClick={() => dismiss()}
              />
            </div>
            <Button
              type="secondary"
              text={`Review ${variant}`}
              disabled={
                !form.shippingAddress ||
                !form.paymentMethodId ||
                (!product.is_flat_rate && !selectedRate)
              }
              width="normal"
              loading={loadingRates || loadingSubmit}
              onClick={() => {
                updateShipping();
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 'confirm',
      title: `Review ${variant === 'offer' ? 'Offer' : 'Bid'}`,
      content: (
        <div className="flex min-h-[42rem] max-w-[44rem] flex-col justify-between gap-[2.4rem] px-[2.4rem]">
          <div className="flex flex-col gap-[0.8rem]">
            {submitError && (
              // error message
              <span className="text-[1.3rem] font-semibold text-red-500">
                {submitError}
              </span>
            )}
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <SafeImage
                  className="rounded-2xl"
                  src={product.thumbnail}
                  width={44}
                  height={44}
                  alt={''}
                />
                <div className="flex flex-col">
                  <h4 className="text-h4 line-clamp-2 leading-8">
                    {product.title}
                  </h4>
                  <span className="text-lg text-brand-gray">
                    {product.category}
                  </span>
                  {selectedVariations?.map((variation) => (
                    <span
                      key={variation.size}
                      className="text-[1.4rem] text-brand-gray"
                    >
                      Size: {variation.size}
                    </span>
                  ))}
                </div>
              </div>
              <span className="font-semibold">
                {formatCurrency(
                  variant === 'offer' ? product.price : getNumber(form.price)
                )}
              </span>
            </div>
            <hr className="mb-4 mt-5"></hr>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between text-[1.3rem] text-brand-gray">
                <span>{variant === 'offer' ? 'Offer' : 'Bid'} Price</span>
                <span>{formatCurrency(getNumber(form.price))}</span>
              </div>
              <div className="flex items-center justify-between text-[1.3rem] text-brand-gray">
                <span>Shipping</span>
                <span>{formatCurrency(shippingCost)}</span>
              </div>
              <div className="flex items-center justify-between text-[1.3rem] text-brand-gray">
                <span>Tax</span>
                <span>{formatCurrency(tax)}</span>
              </div>
              <div className="flex items-center justify-between font-semibold">
                <div>Total (USD)</div>
                <div>
                  {formatCurrency(shippingCost + getNumber(form.price) + tax)}
                </div>
              </div>
            </div>
          </div>
          {form.shippingAddress && (
            <div className="my-4 flex flex-col gap-4">
              <span className="font-semibold">Shipping To</span>
              <AddressCard
                address={JSON.parse(form.shippingAddress)}
                displayOnly
              />
            </div>
          )}

          <div className="relative flex flex-col gap-[1.6rem]">
            <div className="flex gap-4">
              <Checkbox
                label={`I acknowledge that this ${variant} is binding`}
                selected={form.termsAndConditions}
                error={!!errors.termsAndConditions}
                onChange={() => {
                  setValue('termsAndConditions', !form.termsAndConditions);
                }}
              />
              <Disclosure>
                <DisclosureButton>
                  <InformationCircleIcon width={24} height={24} />
                </DisclosureButton>
                <DisclosurePanel className="absolute -top-[10rem] bg-white p-4 shadow-lg">
                  <p className="text-[1.3rem] text-brand-gray">
                    By clicking submit, you are agreeing to the terms and
                    conditions of this {variant}. You will be charged the total
                    amount if the seller accepts your {variant}.
                  </p>
                </DisclosurePanel>
              </Disclosure>
            </div>

            <div className="flex justify-end">
              <div className="mr-4 font-semibold text-brand-secondary">
                <Button
                  buttonType="button"
                  type="text"
                  text="Cancel"
                  width="small"
                  onClick={() => dismiss()}
                />
              </div>
              <Button
                buttonType="submit"
                type="secondary"
                leadingIcon={<LockClosedIcon className="h-5 w-5" />}
                text={variant === 'offer' ? 'Submit Offer' : 'Submit Bid'}
                width="normal"
                loading={loadingSubmit}
                disabled={!product.is_flat_rate && !selectedRate}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={() => {
        dismiss();
      }}
      isFullScreen={isMobile()}
      title={
        currentStep > 0 ? (
          <div className="ml-4 flex items-center lg:-ml-8">
            <Button
              type="text"
              leadingIcon={<ChevronLeftIcon />}
              width="small"
              onClick={() => {
                setSubmitError('');
                setCurrentStep((prev) => prev - 1);
              }}
            />

            <span className="ml-4 text-h3 font-bold">
              {steps.find((_step, index) => index === currentStep)?.title}
            </span>
          </div>
        ) : (
          <span className="ml-4 text-h3 font-bold">
            {variant === 'offer' ? 'Make An Offer' : 'Place Your Bid'}
          </span>
        )
      }
    >
      {!userDoc ? (
        <div className="relative mx-auto mt-[6rem] w-[6rem]">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="p-4 lg:p-0">
          <div className="md:w-[44rem]">
            <BProcess
              steps={steps}
              currentStep={currentStep}
              noStepper={true}
            />
          </div>
        </form>
      )}
    </BaseModal>
  );
};

export default MakeAnOfferModal;
