import { PublicUserDocument } from '@models/user';
import { Elements } from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import { logInitCheckout } from '@util/analytics';
import { getCartItemsSubtotal } from '@util/firestore/cart';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCartItemFromProductDocument, isMobile } from '@util/index';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useStripeContext } from 'context/StripeContext';
import { useToastContext } from 'context/ToastContext';
import dayjs from 'dayjs';
import { HeartIcon } from 'lucide-react';
import { ProductDocument, Variation } from 'models/product';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
const MakeAnOfferModal = dynamic(() => import('../modals/MakeAnOfferModal'));

export function hasValidVariations(
  product: ProductDocument,
  selectedVariations: Variation[] | null
) {
  return (
    !product.has_variations ||
    (product.category2 === 'Gear Combos' && selectedVariations?.length === 2) ||
    (product.category2 !== 'Gear Combos' &&
      (selectedVariations?.length ?? 0) >= 1)
  );
}

export const BuyAddOfferButtons = ({
  product,
  seller,
  selectedVariations,
  onAddToCartError,
  onAddToCart,
  hideOffer,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument | null;
  selectedVariations: Variation[] | null;
  onAddToCartError: () => void;
  onAddToCart?: () => void;
  hideOffer?: boolean;
}) => {
  const { userDoc } = useAuth();
  const { increaseCartQty, canAddProductToCart, setCartOpen } =
    useShoppingCart();
  const [openModal, setOpenModal] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const { setChatOpen } = useChat();
  const { stripePromise } = useStripeContext();
  const { getAttribution } = useAttributionContext();
  const router = useRouter();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastContext();

  React.useEffect(() => {
    if (userDoc) {
      const isFavorite = userDoc.favorites.includes(product.id);
      setLiked(isFavorite);
    }
  }, [userDoc, product.id]);

  const handleAddToCart = () => {
    if (!userDoc) {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
      return;
    }

    if (!hasValidVariations(product, selectedVariations)) {
      showErrorToast('Invalid variations');

      onAddToCartError();
      return;
    }

    if (!canAddProductToCart(product.id)) {
      showErrorToast('Cannot add product to cart');

      setCartOpen(true);
      return;
    }

    const attribution = getAttribution(product.id);
    const cartItem = getCartItemFromProductDocument(
      product,
      selectedVariations,
      attribution
    );

    increaseCartQty(cartItem);
    onAddToCart?.();
  };

  const handleBuyNow = () => {
    if (userDoc) {
      if (hasValidVariations(product, selectedVariations)) {
        if (canAddProductToCart(product.id)) {
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations
          );
          increaseCartQty(cartItem, true); // true to skip opening the cart drawer
        }
        logInitCheckout(
          [product],
          getCartItemsSubtotal([
            getCartItemFromProductDocument(product, selectedVariations),
          ]),
          userDoc?.uid ?? ''
        );
        router.push('/checkout');
      } else {
        onAddToCartError();
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const handleLikeClick = async () => {
    if (!userDoc) return;
    const isFavorite = userDoc?.favorites.includes(product.id);
    if (isFavorite) {
      await removeProductFromFavorites(userDoc.uid, product.id);
    } else {
      await addProductToFavorites(userDoc.uid, product.id);
    }

    setLiked(!liked);

    queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
  };

  const { status } = useOnlineStatus(seller?.uid);
  const today = dayjs();
  const lastChangeDate = dayjs(status?.last_changed);
  const lastSeenInWeeks = today.diff(lastChangeDate, 'week');

  const showBuyButton = !userDoc || (status && lastSeenInWeeks < 2);
  return (
    <>
      <div className="flex flex-col gap-[1rem]">
        {showBuyButton && (
          <Button
            type="secondary"
            text="Buy It Now"
            width="fluid"
            disabled={product.out_of_stock}
            onClick={handleBuyNow}
            className="!rounded-[1.2rem] !text-[1.8rem]"
          />
        )}

        {showBuyButton && (
          <Button
            text="Add to Cart"
            width="fluid"
            type="outline"
            disabled={product.out_of_stock}
            onClick={handleAddToCart}
            className="!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] !text-black"
          />
        )}

        {hideOffer ? (
          <div className="hidden sm:flex">
            <Button
              type="outline"
              width="fluid"
              text={liked ? 'In Favorites' : 'Add to Favorites'}
              leadingIcon={
                <HeartIcon
                  className={`${
                    liked ? '!fill-white' : '!text-black'
                  } h-10 w-10`}
                />
              }
              disabled={product.out_of_stock}
              onClick={handleLikeClick}
              className={`!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] ${
                liked
                  ? '!border-none !bg-brand-secondary !text-white'
                  : '!border-black !bg-inherit !text-black'
              }`}
            />
          </div>
        ) : (
          <Button
            type="outline"
            width="fluid"
            text="Make An Offer"
            disabled={product.out_of_stock}
            onClick={() => {
              if (userDoc) {
                if (hasValidVariations(product, selectedVariations)) {
                  setOpenModal(true);
                } else {
                  onAddToCartError();
                }
              } else {
                router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
              }
            }}
            className="!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] !text-black"
          />
        )}
      </div>

      {openModal && (
        <Elements stripe={stripePromise}>
          <MakeAnOfferModal
            isOpen={openModal}
            dismiss={(openDrawer) => {
              setOpenModal(false);
              if (openDrawer) {
                if (isMobile()) {
                  router.push('/dashboard/offers');
                } else {
                  setChatOpen(true);
                }
              }
            }}
            product={product}
            selectedVariations={selectedVariations}
          />
        </Elements>
      )}
    </>
  );
};

export default BuyAddOfferButtons;
