import { db, functions, httpsCallable } from '@util/firebase';
import {
  CollectionReference,
  DocumentReference,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { ProductDocument } from 'models/product';
import { removeUndefinedRecursive } from '../products';
import { userRef } from '../users';
import {
  EbayTask,
  IntegrationDocument,
  IntegrationSyncError,
  IntegrationTypes,
  TaskDoc,
  UserIntegration,
} from './integrations.types';
import { l } from 'nuqs/dist/serializer-5da93b5e';

type args = { type: IntegrationTypes; uid?: string };

export const colRef = collection(
  db,
  'integrations'
) as CollectionReference<IntegrationDocument>;

export async function getIntegrationList() {
  const { docs } = await getDocs(colRef);
  return docs.map((doc) => doc.data());
}

export async function getIntegrationById(id: string) {
  const snap = await getDoc(doc(colRef, id));
  return snap.data() ?? null;
}

export async function getShopifyIntegrationByUsername(username: string) {
  const c = collection(db, 'integrations');
  const q = query(c, where('username', '==', username));
  const { docs } = await getDocs(q);
  return docs[0]?.data() ?? null;
}

export async function getUserIntegrations(uid: string) {
  if (!uid) return null;
  const col = collection(userRef, uid, 'integrations');
  const { docs } = await getDocs(col);
  return docs.map((doc) => doc.data() as UserIntegration);
}

export async function getAllUserIntegrations(type: IntegrationTypes) {
  if (!type) return null;
  const ref = collectionGroup(db, 'integrations');
  const q = query(ref, where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as UserIntegration);
}

export async function createUserIntegration(
  uid: string,
  type: IntegrationTypes,
  data: Omit<UserIntegration, 'settings' | 'cat_mapper'>
) {
  const ref = doc(userRef, uid, 'integrations', type);
  return setDoc(ref, data as any); // typescript thinks i need to send a userdoc here
}

export async function createIntegrationAdmin({
  uid,
  username,
  client_id,
  client_secret,
  store_domain,
  install_link,
  type,
}: {
  uid: string;
  username: string;
  client_id: string;
  client_secret: string;
  store_domain: string;
  install_link: string;
  type: IntegrationTypes;
}) {
  const ref = doc(userRef, uid, 'integrations', type);
  await setDoc(ref, {
    uid,
    username,
    client_id,
    client_secret,
    store_domain,
    install_link,
    type,
    cat_map_type: 'auto',
  } as any);
  // update user doc to have role of integrations
  const ref2 = doc(userRef, uid);
  await updateDoc(ref2, { 'roles.integrations': true });
}

export function getUserIntegrationQuery(uid: string, type: IntegrationTypes) {
  if (!uid) return null;
  const ref = doc(db, 'users', uid, 'integrations', type);
  return ref as DocumentReference<UserIntegration>;
}

export function getUserIntegrationTaskQuery(uid: string) {
  if (!uid) return null;
  const ref = doc(db, 'ebay_tasks', uid);
  return ref as DocumentReference<TaskDoc>;
}

export function getUserIntegrationErrorQuery({
  uid,
  type,
}: {
  uid: string;
  type: IntegrationTypes;
}) {
  if (!uid) return null;
  const colRef = collection(db, 'integration_errors');
  const q = query(colRef, where('uid', '==', uid), where('type', '==', type));
  return q;
}

export async function getUserIntegration(uid: string, type: IntegrationTypes) {
  if (!uid) return null;
  const ref = doc(db, 'users', uid, 'integrations', type);
  const snap = await getDoc(ref);
  return (snap.data() as UserIntegration) ?? null;
}

export async function setUserIntegrationByKey(
  uid: string,
  type: IntegrationTypes,
  key: keyof UserIntegration,
  value: any
) {
  const val = removeUndefinedRecursive(value);
  const ref = doc(db, 'users', uid, 'integrations', type);
  return updateDoc(ref, { [key]: val });
}

export async function updateUserIntegration(
  uid: string,
  type: IntegrationTypes,
  // allow fields or fieldtypes (deleteField, addField, etc)
  data: Partial<UserIntegration> | { [key: string]: any }
) {
  const ref = doc(db, 'users', uid, 'integrations', type);
  return updateDoc(ref, data);
}

export async function initialSetup(type: IntegrationTypes, code: string) {
  const { data } = await httpsCallable<
    args & { code: string },
    { account_name: string }
  >(
    functions,
    'initialSetup'
  )({ type, code });
  return data;
}

export async function getCategories(type: IntegrationTypes) {
  const { data } = await httpsCallable<args, string[]>(
    functions,
    'integrations-v2_getShopifyCategories'
  )({ type });
  return data;
}

export async function getCollections(type: IntegrationTypes) {
  try {
    const { data } = await httpsCallable<args, any[]>(
      functions,
      'integrations-v2_getShopifyCollections'
    )({ type });
    return data as { id: number; title: string }[];
  } catch (e) {
    alert(e);
    return [];
  }
}

export async function initialInventorySync(type: IntegrationTypes) {
  await httpsCallable<args, { imported: number; total: number }>(
    functions,
    'integrations-v2_enqueueFullSync',
    { timeout: 10 * 60 * 1000 } // 10 minutes
  )({ type });
  return;
}

export async function deleteInventoryFromSync(
  type: IntegrationTypes,
  uid?: string
) {
  const { data } = await httpsCallable<args, { deleted: number }>(
    functions,
    'integrations-v2_deleteInventoryFromSync'
  )({ type, uid });
  return data;
}

export async function removeIntegration({
  uid,
  type,
}: {
  uid: string;
  type: IntegrationTypes;
}) {
  const { data } = await httpsCallable<args, void>(
    functions,
    'integrations-v2_removeIntegration'
  )({ type, uid });
  return data;
}

export async function setupWebhooks() {
  // v2_setupWebhooks
  await httpsCallable<args, void>(
    functions,
    'integrations-v2_setupWebhooks'
  )({
    type: 'shopify',
  });
}
export async function getIntegrationErrors({
  type,
  uid,
}: {
  type: IntegrationTypes;
  uid?: string;
}) {
  if (!uid) return [];
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data()) as IntegrationSyncError[];
}

export async function getIntegrationErrorByProductId(
  productId: string,
  sellerId: string
) {
  const col = collection(db, 'integration_errors');
  const q = query(
    col,
    where('uid', '==', sellerId),
    where('existing_product_id', '==', productId),
    limit(1)
  );
  const { docs } = await getDocs(q);
  return docs[0]?.data() as IntegrationSyncError;
}

export async function getIntegrationErrorCount({
  type,
  uid,
}: {
  type: IntegrationTypes;
  uid?: string;
}) {
  if (!uid) return 0;
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const res = await getCountFromServer(q);
  return res.data().count;
}

export async function getIntegrationProductCount({
  type,
  uid,
}: {
  type: IntegrationTypes;
  uid?: string;
}) {
  if (!uid) return 0;
  const col = collection(db, 'products');
  const q = query(
    col,
    where('integration_info.type', '==', type),
    where('seller_id', '==', uid),
    where('out_of_stock', '==', false)
  );

  const res = await getCountFromServer(q);
  return res.data().count;
}

export async function deleteProductsFromSync({
  ids,
}: {
  ids: string[];
}): Promise<void> {
  await httpsCallable<{ ids: string[] }, void>(
    functions,
    'integrations-v2_deleteProductsFromSync'
  )({ ids });
}

export async function deleteIntegrationError(id: string) {
  const ref = doc(db, 'integration_errors', id);
  // delete the error
  await deleteDoc(ref);
}

export async function deleteAllIntegrationErrors({
  uid,
  type,
}: {
  uid?: string;
  type: IntegrationTypes;
}) {
  if (!uid) throw new Error('No authentication found');
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const { docs } = await getDocs(q);
  await Promise.all(docs.map((doc) => deleteDoc(doc.ref)));
  const ref = doc(db, 'users', uid, 'integrations', type);
  const snap = await getDoc(ref);
  if (snap.exists()) {
    await updateDoc(ref, {
      current_sync_error_count: 0,
    });
  }
}
export async function createIntegrationRequest({
  email,
  store_domain,
  type,
  uid,
}: {
  email: string;
  store_domain: string;
  type: IntegrationTypes;
  uid?: string;
}) {
  const ref = doc(db, 'integration_requests', store_domain);
  await setDoc(ref, { type, email, store_domain, ...(uid && { uid }) });
}

export async function getIntegrationRequests(type: IntegrationTypes) {
  const col = collection(db, 'integration_requests');
  const q = query(col, where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map(
    (doc) =>
      doc.data() as {
        email: string;
        store_domain: string;
        type: IntegrationTypes;
        uid?: string;
      }
  );
}

export async function deleteIntegrationRequest(store_domain: string) {
  const ref = doc(db, 'integration_requests', store_domain);
  await deleteDoc(ref);
}

export async function getIntegrationRequestsCount() {
  const col = collection(db, 'integration_requests');
  const res = await getCountFromServer(col);
  return res.data().count;
}

export async function retryCategorization(product_id: string) {
  await httpsCallable<{ product_id: string }>(
    functions,
    'integrations-v2_retryCategorySync'
  )({ product_id });
}

// Ebay

export async function ebayFullSync(uid?: string) {
  if (!uid) throw new Error('No authentication found');
  const colRef = collection(db, 'ebay_tasks');
  const taskRef = doc(colRef, uid);
  const taskSnap = await getDoc(taskRef);
  const task: EbayTask = {
    task_type: 'GetSellerList',
    task_data: {
      page_number: 1,
    },
    date_created: Date.now(), // TODO: confirm this is the same as the server
  };
  if (!taskSnap.exists()) {
    const newRef = doc(colRef, uid);
    await setDoc(newRef, {
      stop: false,
      tasks: [task],
    });
  } else {
    // Don't set stop false because the backend might be running a task
    await setDoc(
      taskRef,
      {
        tasks: arrayUnion(task),
      },
      { merge: true }
    );
  }
}

export async function ebayToggleSync(uid?: string) {
  if (!uid) throw new Error('No authentication found');
  const colRef = collection(db, 'ebay_tasks');
  const taskRef = doc(colRef, uid);
  const taskSnap = await getDoc(taskRef);
  if (!taskSnap.exists()) return;
  const task = taskSnap.data() as TaskDoc;
  await updateDoc(taskRef, {
    global_stop: !task.global_stop,
  });
  return !task.global_stop;
}

export async function ebaySyncOne({
  remote_id,
  uid,
}: {
  remote_id: string;
  uid?: string;
}) {
  if (!uid) throw new Error('No authentication found');
  const newTask: EbayTask = {
    task_type: 'GetItem',
    task_data: {
      item_id: remote_id,
    },
    date_created: 0, // prioritize this task over all others
  };
  const colRef = collection(db, 'ebay_tasks');
  const taskRef = doc(colRef, uid);
  const taskSnap = await getDoc(taskRef);
  if (!taskSnap.exists()) {
    await setDoc<TaskDoc, TaskDoc>(taskRef, {
      stop: false,
      tasks: arrayUnion(newTask),
    });
  } else {
    await updateDoc<TaskDoc, TaskDoc>(taskRef, {
      tasks: arrayUnion(newTask),
    });
  }
}

export async function completeAuthentication(code: string) {
  const res = await httpsCallable<{ code: string }, void>(
    functions,
    'integrations-ebay-v2_completeAuthentication'
  )({ code });
  return res.data;
}

export async function getSessionID() {
  const res = await httpsCallable<
    void,
    {
      sessionID: string;
      ruName: string;
    }
  >(functions, 'integrations-ebay-v2_getSessionID')();
  return res.data;
}

export async function fetchToken({
  sessionID,
  username,
}: {
  sessionID: string;
  username: string;
}) {
  const res = await httpsCallable<
    {
      sessionID: string;
      username: string;
    },
    void
  >(
    functions,
    'integrations-ebay-v2_fetchToken'
  )({ sessionID, username });
  return res.data;
}

export async function ebayDeleteListings(uid?: string, drafts_only?: boolean) {
  if (!uid) throw new Error('No authentication found');
  const colRef = collection(db, 'ebay_tasks');
  const taskRef = doc(colRef, uid);
  const taskSnap = await getDoc(taskRef);
  if (!taskSnap.exists()) return;
  // Pause the task queue
  await updateDoc(taskRef, {
    global_stop: true,
  });
  await httpsCallable<{ drafts_only?: boolean }, void>(
    functions,
    'integrations-ebay-v2_deleteListings'
  )({ drafts_only });
}

export async function clearAllTasks(uid?: string) {
  if (!uid) return;
  const taskRef = doc(db, 'ebay_tasks', uid);
  const taskSnap = await getDoc(taskRef);
  if (!taskSnap.exists()) return;
  await updateDoc(taskRef, {
    tasks: [],
    stop: false,
    global_stop: true,
  });
}
